<script setup lang="ts">
import { onMounted, ref, type PropType } from 'vue';
import { checkIsMobile } from '../../utils';
import UserMenu from '@/features/profile/UserMenu.vue';
import { isSideBarOpen, isMobile } from './store';
import { dashboardListOpen } from '@/features/dashboardList/store';
import { useStore } from '@nanostores/vue';
import { onSwupEvent } from '../../utils';
import {
  DashboardIcon,
  TaskManagerIcon,
  AlarmIcon,
  DeviceManagementIcon,
} from '@wision/ui';

const props = defineProps({
  pathname: {
    type: String,
    required: true,
  }
});

const config = useRuntimeConfig();
const $showSidebar = useStore(isSideBarOpen);
const $isMobile = useStore(isMobile);

const pathname = ref(props.pathname);
const $dashboardListOpen = useStore(dashboardListOpen);

const isMounted = ref(false);

// console.log(config.public.smallLogoUrl)

const handleOnClickDashboard = (e: MouseEvent) => {
  e.stopPropagation();
  // Uncomment this to reactivate dashboard sidebar
  isSideBarOpen.set(true);
  dashboardListOpen.set(!$dashboardListOpen.value);
};

onMounted(() => {
  isMounted.value = true;
  isMobile.set(checkIsMobile());
  window?.addEventListener('resize', () => {
    isMobile.set(checkIsMobile());
  });
  onSwupEvent(() => {
    dashboardListOpen.set(false);
    pathname.value = window.location.pathname;
    isSideBarOpen.set(false);
  });
});

const isActivePage = (page: string) =>
  pathname.value.toLocaleLowerCase().includes(page);
</script>

<template>
  <nav class="flex bg-black">
    <div
      v-show="$showSidebar && $isMobile"
      id="sidebar-backdrop"
      class="absolute top-0 bottom-0 left-0 right-0 z-10 bg-gray-900 opacity-80"
      @click="isSideBarOpen.set(false)"
    />
    <transition
      enter-active-class="transition-all ease-in-out transform"
      leave-active-class="transition-all ease-in transform"
      enter-from-class="-translate-x-16"
      enter-to-class="translate-x-0"
      leave-to-class="-translate-x-16"
    >
      <div
        v-if="$showSidebar || !$isMobile"
        id="sidebar-content"
        class="absolute z-30 flex flex-col justify-between w-16 h-full py-6.5 text-white bg-dark-widget md:relative md:inline-flex"
      >
        <div class="flex flex-col items-center space-y-4">
          <!-- Wision logo -->
          <div>
            <img
              :src="config.public.smallLogoUrl"
              width="30"
            >
          </div>

          <!-- Dynamic dashboard -->
          <div class="flex justify-between w-full">
            <div class="w-2.5" />
            <button
              class="p-2 rounded"
              :class="{ 'bg-gray-600': isActivePage('dashboard') }"
              @click="handleOnClickDashboard"
            >
              <DashboardIcon />
            </button>
            <div class="flex flex-col justify-center w-3 overflow-hidden">
              <div
                v-if="isActivePage('dashboard')"
                class="h-3 origin-bottom-right transform rotate-45 bg-white dark:bg-dark-menu md:dark:bg-black"
              />
            </div>
          </div>

          <!-- Device management -->
          <div class="flex justify-between w-full">
            <div class="w-9px" />
            <NuxtLink to="/devicemanagement">
              <button
                class="p-[6px] rounded"
                :class="{ 'bg-gray-600': isActivePage('devicemanagement') }"
              >
                <DeviceManagementIcon />
              </button>
            </NuxtLink>
            <div class="flex flex-col justify-center w-3 overflow-hidden">
              <div
                v-if="isActivePage('devicemanagement')"
                class="h-3 origin-bottom-right transform rotate-45 bg-white dark:bg-dark-menu md:dark:bg-black"
              />
            </div>
          </div>
        </div>

        <!-- Lower buttons -->
        <div class="flex flex-col item-center gap-10">
          <UserMenu />
        </div>
      </div>
    </transition>
  </nav>
</template>
