<script setup lang="ts">
import HeaderLogoButton from './HeaderLogoButton.vue';

defineProps({
  title: {
    type: String,
    default: '',
  },
  mobileOnly: {
    type: Boolean,
    default: false
  }
});
const config = useRuntimeConfig();

</script>

<template>
  <header
    id="page-header"
    class="border-b border-[#FE6233] sticky top-0 flex flex-row items-center justify-between m-1 dark:bg-dark-widget z-8 min-h-15 md:min-h-17"
    :class="[
      {
        'md:hidden': mobileOnly
      }
    ]"
  >
    <div
      class="text-white text-xl font-bold md:ml-4 flex items-center"
    >
      <HeaderLogoButton />
      <span>{{ title }}</span>
    </div>
    <div class="flex flex-row items-center">
      <div class="mr-5 text-white">
        <img
          :src="config.public.bigLogoUrl"
          width="100"
        >
      </div>
    </div>
  </header>
</template>
